import requst from '@/utils/request'

// 获取产品列表
export function productList(param) {
  return requst.request({
    url: '/general/product/list',
    method: 'get',
    params: param
  })
}

// 获取产品详情
export function productDetail(param) {
  return requst.request({
    url: '/general/product/detail',
    method: 'get',
    params: param
  })
}

// 添加产品信息
export function addProduct(data) {
  return requst.request({
    url: '/admin/product/add',
    method: 'put',
    data
  })
}

//  编辑产品信息
export function editProduct(data) {
  return requst.request({
    url: '/admin/product/edit',
    method: 'post',
    data
  })
}

//  删除品牌
export function deleteProduct(id) {
  return requst.request({
    url: '/admin/product/delete/' + id,
    method: 'delete',
  })
}

// 添加产品轮播图
export function addProductSlideshow(data) {
  return requst.request({
    url: '/admin/product/slideshow/add',
    method: 'put',
    data
  })
}

//  删除产品轮播图
export function deleteProductSlideshow(id) {
  return requst.request({
    url: '/admin/product/slideshow/delete/' + id,
    method: 'delete',
  })
}