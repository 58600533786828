import { journalismList } from '@/api/journalism/journalism'
const Journalism = {
  namespaced: true,
  state: {
    journalismList: [],
    journalismTotal: 0
  },
  mutations: {
    changeJournalismList(state, list) {
      state.journalismList = list
    },
    changeJournalismTotal(state, total) {
      state.journalismTotal = total
    },
  },
  actions: {
    async getJournalismList({ commit, state }, params) {
      const res = await journalismList(params)
      if (res.success) {
        commit('changeJournalismList', res.data.list)
        commit('changeJournalismTotal', res.data.total)
      }
    }
  }
}

export default Journalism
