export default [
  {
    label: '',
    options: [
      {
        label: '首页',
        value: '/home'
      }
    ]
  },
  {
    label: '',
    options: [
      {
        label: '汉博生态',
        value: '/introduce/digital'
      }
    ]
  },
  {
    label: '',
    options: [
      {
        label: '工程',
        value: '/introduce/engineering'
      }
    ]
  },
  {
    label: '',
    options: [
      {
        label: '联系我们',
        value: '/customer'
      }
    ]
  },
  {
    label: '',
    options: [
      {
        label: '合作品牌',
        value: '/brand'
      }
    ]
  },
  {
    label: '产品中心',
    options: [
      {
        label: '产品中心',
        value: '/introduce/product',
      }
    ]
  },
  {
    label: '行业案例',
    options: [
      {
        label: '教育',
        value: '/industry/education',
      },
      {
        label: '金融',
        value: '/industry/finance',
      },
      {
        label: '制造',
        value: '/industry/fabricate',
      },
      {
        label: '互联网',
        value: '/industry/internet',
      },
      {
        label: '集团',
        value: '/industry/bloc',
      }
    ]
  },
  {
    label: '关于我们',
    options: [
      {
        label: '发展历程',
        value: '/about/course',
      },
      {
        label: '合作伙伴',
        value: '/about/partner',
      },
      {
        label: '公司新闻',
        value: '/about/news',
      },
      {
        label: '行业动态',
        value: '/about/dynamics',
      },
      {
        label: '公司描述',
        value: '/about/description',
      }
    ]
  },
  {
    label: '',
    options: [
      {
        label: '职业招聘',
        value: '/recruitment',
      }
    ]
  },
]