import requst from '@/utils/request'

// 获取轮播信息列表
export function slideshowList(param) {
  return requst.request({
    url: '/admin/slideshow/list',
    method: 'get',
    params: param
  })
}

// 添加轮播信息
export function addSlideshow(data) {
  return requst.request({
    url: '/admin/slideshow/add',
    method: 'put',
    data
  })
}

//  编辑轮播图信息
export function editSlideshow(data) {
  return requst.request({
    url: '/admin/slideshow/edit',
    method: 'post',
    data
  })
}

// 删除轮播图信息
export function deleteSlideshow(id) {
  return requst.request({
    url: '/admin/slideshow/delete/' + id,
    method: 'delete',
  })
}

// 添加页面轮播信息关联
export function addSlideshowWeb(data) {
  return requst.request({
    url: '/admin/slideshow/web/add',
    method: 'put',
    data
  })
}

// 删除页面轮播关联
export function deleteSlideshowWeb(id) {
  return requst.request({
    url: '/admin/slideshow/web/delete/' + id,
    method: 'delete',
  })
}