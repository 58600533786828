import requst from '@/utils/request'

// 获取解决方案列表
export function solutionList(data) {
  return requst.request({
    url: '/general/solution/list',
    method: 'get',
    params: data
  })
}


// 获取解决方案详情
export function solutionDetail(id) {
  return requst.request({
    url: '/general/solution/detail/' + id,
    method: 'get'
  })
}

// 添加解决方案
export function addSolution(data) {
  return requst.request({
    url: '/admin/solution/add',
    method: 'put',
    data,
  })
}


// 编辑解决方案
export function editSolution(data) {
  return requst.request({
    url: '/admin/solution/edit',
    method: 'post',
    data,
  })
}

// 删除解决方案
export function deleteSolution(id) {
  return requst.request({
    url: '/admin/solution/delete/' + id,
    method: 'delete',
  })
}


// 添加产品关联
export function addSolutionProductBind(data) {
  return requst.request({
    url: '/admin/solution/product/add',
    method: 'put',
    data,
  })
}

// 添加案例关联
export function addSolutiontIndustryBind(data) {
  return requst.request({
    url: '/admin/solution/industry/add',
    method: 'put',
    data,
  })
}