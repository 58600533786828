import { slideshowList } from '@/api/slideshow/slideshow'
import clientRouteList from '@/utils/clientRouteList'
const Slideshow = {
  namespaced: true,
  state: {
    slideshowList: [],
    slideshowTotal: 0
  },
  mutations: {
    changeSlideshowList(state, list) {
      state.slideshowList = list
    },
    changeSlideshowTotal(state, total) {
      state.slideshowTotal = total
    },
  },
  actions: {
    async getSlideshowList({ commit, state }, params) {
      const res = await slideshowList(params)
      if (res.success) {
        res.data.list.forEach(item1 => {
          item1.view.forEach(item2 => {
            item2.view_name = ''
            clientRouteList.forEach(v1 => {
              v1.options.forEach(v2 => {
                if (v2.value === item2.route) {
                  item2.view_name = v2.label
                }
              })
            })
          })
        })
        commit('changeSlideshowList', res.data.list)
        commit('changeSlideshowTotal', res.data.total)
      }
    }
  }
}

export default Slideshow
