export default {
  install (Vue) {
    // 时间转换（将毫秒转换成时间格式）
    Vue.filter('dateTimeFormat', function (originVal) {
      const dt = new Date(originVal)
      const y = dt.getFullYear() // 年
      const m = (dt.getMonth() + 1 + '').padStart(2, '0') // 月
      const d = (dt.getDate() + '').padStart(2, '0') // 日

      const hh = (dt.getHours() + '').padStart(2, '0') // 小时
      const mm = (dt.getMinutes() + '').padStart(2, '0') // 分
      const ss = (dt.getSeconds() + '').padStart(2, '0') // 秒
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`
    })
    Vue.filter('dateFormat', function theDateFormat (originVal) {
      const dt = new Date(originVal)
      const y = dt.getFullYear() // 年
      const m = (dt.getMonth() + 1 + '').padStart(2, '0') // 月
      const d = (dt.getDate() + '').padStart(2, '0') // 日
      return `${y}-${m}-${d}`
    })

    // 格式化文件大小
    Vue.filter('fileSiseFormat', function (data) {
      const size = data / 1024
      if (size < 1024) {
        return Math.floor(size * 100) / 100 + 'KB'
      } else {
        return Math.floor(size / 1024 * 100) / 100 + 'MB'
      }
    })

    Vue.filter('solutionTypeFormat', function (type) {
      if (type === 1) {
        return '混合云解决方案'
      } else if (type === 2) {
        return '网站解决方案'
      } else if (type === 3) {
        return '行业解决方案'
      } else if (type === 4) {
        return '工程解决方案'
      } else {
        return type
      }
    })

    Vue.filter('brandFormat', function (type) {
      if (type == 0) {
        return '其他'
      } else if (type == 1) {
        return 'CISCO'
      } else if (type == 2) {
        return '华为'
      } else if (type == 3) {
        return '锐捷'
      } else if (type == 4) {
        return 'ruckus'
      } else if (type == 5) {
        return 'DELL'
      } else if (type == 6) {
        return 'HP'
      } else if (type == 7) {
        return 'ATOS'
      } else if (type == 8) {
        return 'inspur'
      } else if (type == 9) {
        return 'IBM'
      } else if (type == 10) {
        return '海康威视'
      } else if (type == 11) {
        return 'LENOVO'
      } else {
        return type
      }
    })

    Vue.filter('brandTypeFormat', function (type) {
      if (type == 0) {
        return '其他'
      } else if (type == 1) {
        return '交换机'
      } else if (type == 2) {
        return '路由器'
      } else if (type == 3) {
        return '防火墙'
      } else if (type == 4) {
        return 'AC控制器'
      } else if (type == 5) {
        return 'AP'
      } else if (type == 6) {
        return '服务器'
      } else if (type == 7) {
        return '存储'
      } else {
        return type
      }
    })

    Vue.filter('productTypeFormat', function (type) {
      if (type == 1) {
        return '服务器'
      } else if (type == 2) {
        return '存储'
      } else if (type == 3) {
        return '网络传输'
      } else if (type == 4) {
        return '周边配件'
      } else {
        return type
      }
    })

    Vue.filter('journalismTypeFormat', function (type) {
      if (type == 1) {
        return '公司新闻'
      } else if (type == 2) {
        return '行业动态'
      } else {
        return type
      }
    })

    Vue.filter('industryTypeFormat', function (type) {
      if (type == 1) {
        return '教育'
      } else if (type == 2) {
        return '金融'
      } else if (type == 3) {
        return '制造'
      } else if (type == 4) {
        return '互联网'
      } else if (type == 5) {
        return '集团'
      } else {
        return type
      }
    })

    Vue.filter('renewalTypeFormat', function (type) {
      if (type == 0) {
        return '其他'
      } else if (type == 1) {
        return '存储备份软件'
      } else if (type == 2) {
        return '服务器'
      } else if (type == 3) {
        return '存储'
      } else {
        return type
      }
    })
  }
}