import { productList } from '@/api/product/product'
const Product = {
  namespaced: true,
  state: {
    queryInfo: {
      cur: 1,
      size: 20,
      type: 0,
    },
    productList: [],
    productTotal: 0
  },
  mutations: {
    changeProductList(state, list) {
      state.productList = list
    },
    changeProductTotal(state, total) {
      state.productTotal = total
    },
    changeQueryInfo(state, queryInfo) {
      for (const key in queryInfo) {
        state.queryInfo[key] = queryInfo[key]
      }
    }
  },
  actions: {
    async getProductList({ commit, state }) {
      const res = await productList(state.queryInfo)
      if (res.success) {
        commit('changeProductList', res.data.list)
        commit('changeProductTotal', res.data.total)
      }
    }
  }
}

export default Product
