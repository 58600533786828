import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import pluhins from '@/utils/pluhins'
import ElementUI from 'element-ui'
import 'normalize.css'
import 'element-ui/lib/theme-chalk/index.css'
import requst from './utils/request'
import vueEsign from 'vue-esign'

// import Vconsole from "vconsole";
// new Vconsole()

Vue.prototype.$http = requst

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(vueEsign)
Vue.use(pluhins)
new Vue({
  router,
  store,
  render: (h) => h(App),
  created() {
    this.getSEO()
  },
  data: {
    title:
      '汉博利特管理后台',
    description:
      '汉博利特管理后台',
    keywords:
      '汉博利特管理后台'
  },
  methods: {
    getSEO() {
      document.title = this.title || '汉博利特'
      document.querySelector('meta[name="keywords"]').setAttribute('content', this.keywords)
      document.querySelector('meta[name="description"]').setAttribute('content', this.description)
    }
  }
}).$mount('#app')
