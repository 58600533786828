import { cooperativeList } from '@/api/cooperative/cooperative'
const Cooperative = {
  namespaced: true,
  state: {
    cooperativeList: [],
    cooperativeTotal: 0
  },
  mutations: {
    changeCooperativeList(state, list) {
      state.cooperativeList = list
    },
    changeCooperativeTotal(state, total) {
      state.cooperativeTotal = total
    },
  },
  actions: {
    async getCooperativeList({ commit, state }, params) {
      const res = await cooperativeList(params)
      if (res.success) {
        commit('changeCooperativeList', res.data.list)
        commit('changeCooperativeTotal', res.data.total)
      }
    }
  }
}

export default Cooperative
