import requst from '@/utils/request'

// 获取岗位列表
export function employmentList(param) {
  return requst.request({
    url: '/general/employment/list',
    method: 'get',
    params: param
  })
}

// 添加岗位
export function addEmployment(data) {
  return requst.request({
    url: '/admin/employment/add',
    method: 'put',
    data
  })
}

//  编辑产品信息
export function editEmployment(data) {
  return requst.request({
    url: '/admin/employment/edit',
    method: 'post',
    data
  })
}

//  删除品牌
export function deleteEmployment(id) {
  return requst.request({
    url: '/admin/employment/delete/' + id,
    method: 'delete',
  })
}