import { developmentList } from '@/api/development/development'
const Development = {
  namespaced: true,
  state: {
    developmentList: [],
    developmentTotal: 0
  },
  mutations: {
    changeDevelopmentList(state, list) {
      state.developmentList = list
    },
    changeDevelopmentTotal(state, total) {
      state.developmentTotal = total
    },
  },
  actions: {
    async getDevelopmentList({ commit, state }, params) {
      const res = await developmentList(params)
      if (res.success) {
        commit('changeDevelopmentList', res.data.list)
        commit('changeDevelopmentTotal', res.data.total)
      }
    }
  }
}

export default Development
