import requst from '@/utils/request'

// 获取菜单栏目列表
export function getMenusList(param) {
  return requst.request({
    url: '/admin/menus/list',
    method: 'get',
    params: param
  })
}

// 添加菜单栏目
export function addMenu(data) {
  return requst.request({
    url: '/admin/menus/add',
    method: 'put',
    data
  })
}

// 编辑菜单栏目
export function editMenu(data) {
  return requst.request({
    url: '/admin/menus/edit',
    method: 'post',
    data
  })
}

//删除菜单栏目
export function deleteMenu(id) {
  return requst.request({
    url: '/admin/menus/delete/' + id,
    method: 'delete',
  })
}

// 搜索文档列表（产品、解决方案、新闻与动态、行业案例）
export function docSearch(param) {
  return requst.request({
    url: '/admin/doc/search',
    method: 'get',
    params: param
  })
}