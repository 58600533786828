import { technicalList } from '@/api/technical/technical'
const Technical = {
  namespaced: true,
  state: {
    technicalList: [],
    technicalTotal: 4
  },
  mutations: {
    changeTechnicalList(state, list) {
      state.technicalList = list
    },
  },
  actions: {
    async getTechnicalList({ commit }) {
      const res = await technicalList()
      if (res.success) {
        commit('changeTechnicalList', res.data.list)
      }
    }
  }
}

export default Technical
