import requst from '@/utils/request'


// 获取行业列表
export function getIndustryInfoList (param) {
  return requst.request({
    url: '/general/industry',
    method: 'get',
    params: param
  })
}

// 添加行业列表
export function addIndustryInfo (data) {
  return requst.request({
    url: '/admin/industry/info/add',
    method: 'put',
    data
  })
}

// 编辑行业列表
export function editIndustryInfo (data) {
  return requst.request({
    url: '/admin/industry/info/edit',
    method: 'post',
    data
  })
}

// 删除行业列表
export function deleteIndustryInfo (id) {
  return requst.request({
    url: '/admin/industry/info/delete/' + id,
    method: 'delete',
  })
}

// 获取行业案例列表
export function industryList(param) {
  return requst.request({
    url: '/general/industry/list',
    method: 'get',
    params: param
  })
}

// 添加行业案例信息
export function addIndustry(data) {
  return requst.request({
    url: '/admin/industry/add',
    method: 'put',
    data
  })
}

//  编辑行业案例信息
export function editIndustry(data) {
  return requst.request({
    url: '/admin/industry/edit',
    method: 'post',
    data
  })
}

// 删除行业案例信息
export function deleteIndustry(id) {
  return requst.request({
    url: '/admin/industry/delete/' + id,
    method: 'delete',
  })
}