import { employmentList } from '@/api/employment/employment'
const Employment = {
  namespaced: true,
  state: {
    employmentList: [],
    employmentTotal: 0
  },
  mutations: {
    changeEmploymentList(state, list) {
      state.employmentList = list
    },
    changeEmploymentTotal(state, total) {
      state.employmentTotal = total
    },
  },
  actions: {
    async getEmploymentList({ commit, state }, params) {
      const res = await employmentList(params)
      if (res.success) {
        commit('changeEmploymentList', res.data.list)
        commit('changeEmploymentTotal', res.data.total)
      }
    }
  }
}

export default Employment
