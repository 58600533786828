const allList = [
  {
    title: '栏目管理',
    id: 17,
    icon: 'el-icon-menu',
    path: '/main/menus',
    isAdmin: false
  },
  {
    title: '数字化管理',
    id: 1,
    icon: 'el-icon-cloudy',
    path: '/main/digitization',
    isAdmin: false
  },
  {
    title: '轮播图管理',
    id: 2,
    icon: 'el-icon-picture-outline',
    path: '/main/slideshow',
    isAdmin: false
  },
  {
    title: '方案管理',
    id: 3,
    icon: 'el-icon-notebook-2',
    path: '/main/solution',
    isAdmin: false
  },
  {
    title: '服务管理',
    id: 4,
    icon: 'el-icon-coordinate',
    path: '/main/technical',
    isAdmin: false
  },
  {
    title: '新闻动态管理',
    id: 5,
    icon: 'icon-guanggaoguanli',
    path: '/main/journalism',
    isAdmin: false
  },
  {
    title: '行业管理',
    id: 18,
    icon: 'el-icon-data-line',
    path: '/main/industry',
    isAdmin: false
  },
  {
    title: '行业案例管理',
    id: 6,
    icon: 'el-icon-data-line',
    path: '/main/industry/case',
    isAdmin: false
  },
  {
    title: '合作伙伴管理',
    id: 7,
    icon: 'el-icon-c-scale-to-original',
    path: '/main/cooperative',
    isAdmin: false
  },
  {
    title: '发展历程管理',
    id: 8,
    icon: 'el-icon-help',
    path: '/main/development',
    isAdmin: false
  },
  {
    title: '品牌管理',
    id: 9,
    icon: 'el-icon-office-building',
    path: '/main/brand',
    isAdmin: false
  },
  {
    title: '产品管理',
    id: 10,
    icon: 'el-icon-box',
    path: '/main/product',
    isAdmin: false
  },
  {
    title: '报修管理',
    id: 11,
    icon: 'el-icon-document-delete',
    path: '/main/repair',
    isAdmin: false
  },
  {
    title: 'DELL续保',
    id: 12,
    icon: 'el-icon-bank-card',
    path: '/main/renewal',
    isAdmin: false
  },
  {
    title: '留言管理',
    id: 13,
    icon: 'el-icon-chat-line-round',
    path: '/main/feedback',
    isAdmin: false
  },
  {
    title: '招聘管理',
    id: 14,
    icon: 'el-icon-position',
    path: '/main/employment',
    isAdmin: false
  },
  {
    title: '账户管理',
    id: 15,
    icon: 'el-icon-user',
    path: '/main/adminUser',
    isAdmin: true
  },
  {
    title: '角色管理',
    id: 16,
    icon: 'el-icon-thumb',
    path: '/main/role',
    isAdmin: true
  }
]

// 检查id
allList.forEach(item1 => {
  allList.forEach(item2 => {
    if (item1.id === item2.id && item1.path !== item2.path) {
      throw new Error('导航菜单配置ID重复')
    }
  })
})

export default (isAdmin = false, permissionIds = []) => {
  if (isAdmin) {
    return allList
  } else {
    if (!Array.isArray(permissionIds) || !permissionIds.length) return []
    const newList = []
    allList.forEach(item1 => {
      const coll = permissionIds.some(v => Number(v.id) === Number(item1.id))
      if (coll && !item1.isAdmin) {
        newList.push(item1)
      }
    })
    return newList
  }
}
