import { getMenusList } from '@/api/menus/menus'
const Product = {
  namespaced: true,
  state: {
    labelList: [],
    menuList: []
  },
  mutations: {
    changeMenus(state, data) {
      for (const key in data) {
        if (key === 'menu' || key === 'label') {
          data[key].forEach(item1 => {
            item1.button_add_text = '添加子菜单'
            item1.button_del_text = '删除'
            const groupIndexList = []
            item1.children.forEach((item, index) => {
              groupIndexList.push({
                label: '分组' + (index + 1),
                value: index
              })
            })
            item1.groupIndexList = groupIndexList
            const newChildren = []
            item1.children.forEach((item2, index) => {
              item2.forEach(v => {
                v.button_del_text = '删除子菜单'
                v.groupIndexList = groupIndexList
              })
              newChildren.push({
                id: new Date().getTime() + index,
                title_cn: '子菜单组' + (index + 1),
                title_us: 'Submenu group' + (index + 1),
                children: item2,
                type: 'group',
              })
            })
            item1.children = newChildren
          })
        }
      }
      state.labelList = data.label
      state.menuList = data.menu
    },
  },
  actions: {
    async getMenus({ commit }) {
      const res = await getMenusList()
      if (res.success) {
        commit('changeMenus', res.data)
      }
    }
  }
}

export default Product
