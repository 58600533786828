import { getUserList, getUserRoleList } from '@/api/adminUser/adminUser'
const cases = {
  namespaced: true,
  state: {
    userList: [],
    userTotal: 0,
    roleList: [],
  },
  mutations: {
    changeUserList(state, list) {
      state.userList = list
    },
    changeUserTotal(state, total) {
      state.userTotal = total
    },
    changeRoleList(state, list) {
      state.roleList = list
    }
  },
  actions: {
    async getUserList({ commit }, param = { cur: 1, size: 10 }) {
      let result = await getUserList(param)
      let { data, success } = result
      if (success) {
        commit('changeUserList', data.list)
        commit('changeUserTotal', data.total)
      }
    },
    // 获取角色列表
    async getUserRoleList({ commit }) {
      const result = await getUserRoleList({ cur: 1, size: 100 })
      if (result.success) {
        commit('changeRoleList', result.data.list)
      }
    }
  }
}

export default cases
