import { feedbackList } from '@/api/feedback/feedback'
const Feedback = {
  namespaced: true,
  state: {
    feedbackList: [],
    feedbackTotal: 0
  },
  mutations: {
    changeFeedbackList(state, list) {
      state.feedbackList = list
    },
    changeFeedbackTotal(state, total) {
      state.feedbackTotal = total
    },
  },
  actions: {
    async getFeedbackList({ commit, state }, params) {
      const res = await feedbackList(params)
      if (res.success) {
        commit('changeFeedbackList', res.data.list)
        commit('changeFeedbackTotal', res.data.total)
      }
    }
  }
}

export default Feedback
