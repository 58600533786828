import requst from '@/utils/request'

//  获取报修列表
export function repairList(data) {
  return requst.request({
    url: '/admin/repair/list',
    method: 'get',
    params: data
  })
}


// 修改报修状态
export function setRepairState(data) {
  return requst.request({
    url: '/admin/repair/state',
    method: 'post',
    data,
  })
}

// 修改报修备注
export function setRepairComment(data) {
  return requst.request({
    url: '/admin/repair/comment',
    method: 'post',
    data,
  })
}
