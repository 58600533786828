import requst from '@/utils/request'

// 获取发展历程列表
export function developmentList(param) {
  return requst.request({
    url: '/general/development/list',
    method: 'get',
    params: param
  })
}

// 添加发展历程
export function addDevelopment(data) {
  return requst.request({
    url: '/admin/development/add',
    method: 'put',
    data
  })
}

//  编辑发展历程
export function editDevelopment(data) {
  return requst.request({
    url: '/admin/development/edit',
    method: 'post',
    data
  })
}

// 删除发展历程
export function deleteDevelopment(id) {
  return requst.request({
    url: '/admin/development/delete/' + id,
    method: 'delete',
  })
}