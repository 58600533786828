import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router'

// 导入 nprogress 顶部进度条条 包对应的js和css
import Nprogress from 'nprogress'
import 'nprogress/nprogress.css'

export let BaseURL
if (process.env.NODE_ENV === 'development') {
  BaseURL = '/api'
} else if (process.env.NODE_ENV === 'production') {
  BaseURL = '/api'
}

let requst = axios.create({
  baseURL: BaseURL,
  timeout: 15000,
  headers: {
    'content-type': 'application/json'
  }
})
requst.interceptors.request.use((req) => {
  let token = localStorage.getItem('token')
  if (token) {
    req.headers['authorization'] = token
  }
  Nprogress.start() // 展示进度条
  return req
})
requst.interceptors.response.use((res) => {
  Nprogress.done() // 隐藏进度条
  if (!res.data.success) {
    Message({
      type: 'error',
      message: res.data.returnMsg
    })
  }
  if (!res.data.success && res.data.returnCode == '401') {
    localStorage.setItem('token', '')
    router.push('/login')
  }
  return res.data
}, err => {
  Nprogress.done()
  Message({
    type: 'error',
    message: err.message
  })
  throw error
})

export default requst
