import requst from '@/utils/request'

//  获取留言信息列表
export function feedbackList(param) {
  return requst.request({
    url: '/admin/feedback/list',
    method: 'get',
    params: param
  })
}

// 修改留言信息状态
export function editFeedbackState(data) {
  return requst.request({
    url: '/admin/feedback/state',
    method: 'post',
    data
  })
}

// 修改留言信息备注
export function editFeedbackComment(data) {
  return requst.request({
    url: '/admin/feedback/comment',
    method: 'post',
    data
  })
}
