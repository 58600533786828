import { brandList } from '@/api/brand/brand'
const Brand = {
  namespaced: true,
  state: {
    brandList: [],
    brandTotal: 0,
    brandSelectList: [],
  },
  mutations: {
    changeBrandList(state, list) {
      state.brandList = list
    },
    changeBrandTotal(state, total) {
      state.brandTotal = total
    },
    changeBrandSelectList(state, list) {
      state.brandSelectList = list
    },
  },
  actions: {
    async getBrandList({ commit }, params) {
      const res = await brandList(params)
      if (res.success) {
        commit('changeBrandList', res.data.list)
        commit('changeBrandTotal', res.data.total)
      }
    },
    async getBrandSelectList({ commit }, params) {
      const res = await brandList(params)
      if (res.success) {
        const list = res.data.list.map(v => {
          return {
            label: v.brand_name_cn,
            value: v.id
          }
        })
        commit('changeBrandSelectList', list)
      }
    }
  }
}

export default Brand
