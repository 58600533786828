import requst from '@/utils/request'

// 获取账户列表
export function getDigitizationData(param) {
  return requst.request({
    url: '/general/digitization/list',
    method: 'get',
    params: param
  })
}

// 编辑自媒体配置信息
export function editWeMedia(data) {
  return requst.request({
    url: '/admin/digitization/weMedia',
    method: 'post',
    data
  })
}

// 编辑其他配置
export function editRests(data) {
  return requst.request({
    url: '/admin/digitization/rests',
    method: 'post',
    data
  })
}
