import Vue from 'vue'
import VueRouter from 'vue-router'
import navigation from '@/utils/navigation'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/login.vue')
  },
  {
    path: '/main',
    name: 'main',
    redirect: 'digitization',
    component: () => import('@/views/main/main.vue'),
    children: []
  }
]

const mainChildren = [
  {
    path: '/main/adminUser',
    name: 'adminUser',
    component: () => import('@/views/main/adminUser/adminUser.vue')
  },
  {
    path: '/main/brand',
    name: 'brand',
    component: () => import('@/views/main/brand/brand.vue')
  },
  {
    path: '/main/cooperative',
    name: 'cooperative',
    component: () => import('@/views/main/cooperative/cooperative.vue')
  },
  {
    path: '/main/development',
    name: 'development',
    component: () => import('@/views/main/development/development.vue')
  },
  {
    path: '/main/development',
    name: 'development',
    component: () => import('@/views/main/development/development.vue')
  },
  {
    path: '/main/digitization',
    name: 'digitization',
    component: () => import('@/views/main/digitization/digitization.vue')
  },
  {
    path: '/main/employment',
    name: 'employment',
    component: () => import('@/views/main/employment/employment.vue')
  },
  {
    path: '/main/feedback',
    name: 'feedback',
    component: () => import('@/views/main/feedback/feedback.vue')
  },
  {
    path: '/main/industry',
    name: 'industrys',
    component: () => import('@/views/main/industry/industry.vue')
  },
  {
    path: '/main/industry/case',
    name: 'industryCase',
    component: () => import('@/views/main/industryCase/industryCase.vue')
  },
  {
    path: '/main/journalism',
    name: 'journalism',
    component: () => import('@/views/main/journalism/journalism.vue')
  },
  {
    path: '/main/product',
    name: 'product',
    component: () => import('@/views/main/product/product.vue'),
  },
  {
    path: '/main/product/slideshow',
    name: 'productSlideshow',
    meta: {
      path: '/main/product'
    },
    component: () => import('@/views/main/product/slideshow.vue'),
  },
  {
    path: '/main/renewal',
    name: 'renewal',
    component: () => import('@/views/main/renewal/renewal.vue')
  },
  {
    path: '/main/repair',
    name: 'repair',
    component: () => import('@/views/main/repair/repair.vue')
  },
  {
    path: '/main/role',
    name: 'role',
    component: () => import('@/views/main/role/role.vue')
  },
  {
    path: '/main/slideshow',
    name: 'slideshow',
    component: () => import('@/views/main/slideshow/slideshow.vue')
  },
  {
    path: '/main/solution',
    name: 'solution',
    component: () => import('@/views/main/solution/solution.vue')
  },
  {
    path: '/main/technical',
    name: 'technical',
    component: () => import('@/views/main/technical/technical.vue')
  },
  {
    path: '/main/menus',
    name: 'menus',
    component: () => import('@/views/main/menus/menus.vue')
  },
]

const router = new VueRouter({
  routes,
  mode: 'hash'
})
router.beforeEach((to, from, next) => {
  if (to.path !== '/login' && to.path !== '/information' && to.path !== '/main/invoices') {
    // 获取浏览器中的token进行认证
    const tokenStr = localStorage.getItem('token')
    if (!tokenStr) return next('/login')
    const routerList = router.getRoutes()
    if (routerList.length <= 3) {
      // 动态添加路由
      const userMsg = localStorage.getItem('userMsg')
      if (!userMsg) return next('/')
      const userMsgObj = JSON.parse(userMsg)
      const list = navigation(userMsgObj.is_admin, userMsgObj.permission)
      list.forEach(item1 => {
        mainChildren.forEach((child, i) => {
          if (child.path === item1.path || child.meta?.path === item1.path) {
            const routeColl = routerList.some(v => v.path === item1.path)
            if ((!routeColl)) {
              router.addRoute('main', child)
            }
          }
        })
      })
      router.replace({
        path: to.path,
        params: to.params,
        query: to.query
      })
    }
  }
  next()
})

export default router
