import { getDigitizationData } from '@/api/digitization/digitization'
const Digitization = {
  namespaced: true,
  state: {
    digitizationData: '',
  },
  mutations: {
    changeDigitizationData(state, digitizationData) {
      state.digitizationData = digitizationData
    },
    delWeMediaConfig (state, index) {
      state.digitizationData.we_media.splice(index, 1)
    },
    addWeMediaConfig (state) {
      const data = {
        title: '',
        image_path: '',
        route: '',
        param: ''
      }
      state.digitizationData.we_media.push({
        layout_data_cn: JSON.parse(JSON.stringify(data)),
        layout_data_us: JSON.parse(JSON.stringify(data)),
      })
    },
    delRestConfig (state, index) {
      state.digitizationData.rests.splice(index, 1)
    },
    addRestConfig (state) {
      const data = {
        title: '',
        subhead: '',
        image_path: '',
        route: '',
        param: ''
      }
      state.digitizationData.rests.push({
        layout_data_cn: JSON.parse(JSON.stringify(data)),
        layout_data_us: JSON.parse(JSON.stringify(data)),
      })
    },
  },
  actions: {
    async getData({ commit }, params) {
      const res = await getDigitizationData(params)
      if (res.success) {
        if (!res.data.we_media.layout_data_cn || !res.data.we_media.layout_data_us) {
          const data = {
            title: '',
            image_path: '',
            route: '',
            param: ''
          }
          res.data.we_media.layout_data_cn = [data]
          res.data.we_media.layout_data_us = [data]
        }
        if (!res.data.rests.layout_data_cn || !res.data.rests.layout_data_us || !res.data.rests.layout_data_cn.length || !res.data.rests.layout_data_us.length) {
          const data = {
            title: '',
            subhead: '',
            image_path: '',
            route: '',
            param: ''
          }
          res.data.rests.layout_data_cn = [data]
          res.data.rests.layout_data_us = [data]
        }
        const newMedia = []
        res.data.we_media.layout_data_cn.forEach((item, index) => {
          newMedia.push({
            layout_data_cn: JSON.parse(JSON.stringify(item)),
            layout_data_us: res.data.we_media.layout_data_us[index] ? JSON.parse(JSON.stringify(res.data.we_media.layout_data_us[index])) : JSON.parse(JSON.stringify(data))
          })
        })
        res.data.we_media = newMedia
        const newRests = []
        res.data.rests.layout_data_cn.forEach((item, index) => {
          newRests.push({
            layout_data_cn: JSON.parse(JSON.stringify(item)),
            layout_data_us: res.data.rests.layout_data_us[index] ? JSON.parse(JSON.stringify(res.data.rests.layout_data_us[index])) : JSON.parse(JSON.stringify(data))
          })
        })
        res.data.rests = newRests
        commit('changeDigitizationData', res.data)
      }
    }
  }
}

export default Digitization
