import requst from '@/utils/request'

// 获取续保信息列表
export function renewalList(param) {
  return requst.request({
    url: '/admin/renewal/list',
    method: 'get',
    params: param
  })
}

// 修改续保信息状态
export function editRenewalState(data) {
  return requst.request({
    url: '/admin/renewal/state',
    method: 'post',
    data
  })
}

//  修改续保信息备注
export function editRenewalComment(data) {
  return requst.request({
    url: '/admin/renewal/comment',
    method: 'post',
    data
  })
}
