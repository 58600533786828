import { getUserRoleList } from '@/api/adminUser/adminUser'
const cases = {
  namespaced: true,
  state: {
    roleList: [],
    roleTotal: 0,
  },
  mutations: {
    changeRoleTotal(state, total) {
      state.roleTotal = total
    },
    changeRoleList(state, list) {
      state.roleList = list
    }
  },
  actions: {
    // 获取角色列表
    async getUserRoleList({ commit }, param = { cur: 1, size: 10 }) {
      const result = await getUserRoleList(param)
      if (result.success) {
        commit('changeRoleList', result.data.list)
        commit('changeRoleTotal', result.data.total)
      }
    }
  }
}

export default cases
