import requst from '@/utils/request'

// 获取新闻与动态列表
export function journalismList(param) {
  return requst.request({
    url: '/general/journalism/list',
    method: 'get',
    params: param
  })
}

// 添加新闻或动态
export function addJournalism(data) {
  return requst.request({
    url: '/admin/journalism/add',
    method: 'put',
    data
  })
}

//  编辑新闻或动态
export function editJournalism(data) {
  return requst.request({
    url: '/admin/journalism/edit',
    method: 'post',
    data
  })
}

// 删除新闻或动态
export function deleteJournalism(id) {
  return requst.request({
    url: '/admin/journalism/delete/' + id,
    method: 'delete',
  })
}