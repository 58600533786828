import Vue from 'vue'
import Vuex from 'vuex'
import login from './login/login'
import adminUser from './adminUser/adminUser'
import brand from './brand/brand'
import cooperative from './cooperative/cooperative'
import development from './development/development'
import employment from './employment/employment'
import feedback from './feedback/feedback'
import industry from './industry/industry'
import journalism from './journalism/journalism'
import product from './product/product'
import renewal from './renewal/renewal'
import repair from './repair/repair'
import slideshow from './slideshow/slideshow'
import role  from './role/role'
import technical from './technical/technical'
import digitization from './digitization/digitization'
import solution from './solution/solution'
import menus from './menus/menus'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    login,
    adminUser,
    brand,
    cooperative,
    development,
    employment,
    feedback,
    industry,
    journalism,
    product,
    renewal,
    repair,
    slideshow,
    technical,
    role,
    digitization,
    solution,
    menus
  }
})
