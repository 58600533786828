import { Login } from '@/api/login/login'
import router from '@/router/index'
import { BaseURL } from '@/utils/request'
import md5 from 'js-md5';
const login = {
  namespaced: true,
  state: {
    token: '',
    codeImg: '',
  },
  mutations: {
    changeToken(state, token) {
      state.token = token
    },
    changeCodeImg(state) {
      state.codeImg = BaseURL + '/admin/checkCode' + '?t=' + new Date().getTime()
    }
  },
  actions: {
    async toLogin({ commit }, payload) {
      let res = await Login({
        username: payload.username,
        password: md5(payload.password),
        checkCode: payload.checkCode
      })
      if (res.success && res.data.token) {
        commit('changeToken', res.data.token)
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('userMsg', JSON.stringify(res.data))
        router.push('/main/digitization')
      } else {
        commit('changeCodeImg')
      }
    }
  }
}

export default login
