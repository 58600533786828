import requst from '@/utils/request'

//  获取技术服务列表
export function technicalList(data) {
  return requst.request({
    url: '/general/technical/list',
    method: 'get',
    params: data
  })
}


// 编辑技术服务信息
export function editTechnical(data) {
  return requst.request({
    url: '/admin/technical/edit',
    method: 'post',
    data,
  })
}
