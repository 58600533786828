import { renewalList } from '@/api/renewal/renewal'
const Renewal = {
  namespaced: true,
  state: {
    renewalList: [],
    renewalTotal: 0
  },
  mutations: {
    changeRenewalList(state, list) {
      state.renewalList = list
    },
    changeRenewalTotal(state, total) {
      state.renewalTotal = total
    },
  },
  actions: {
    async getRenewalList({ commit, state }, params) {
      const res = await renewalList(params)
      if (res.success) {
        commit('changeRenewalList', res.data.list)
        commit('changeRenewalTotal', res.data.total)
      }
    }
  }
}

export default Renewal
