import { repairList } from '@/api/repair/repair'
const Repair = {
  namespaced: true,
  state: {
    repairList: [],
    repairTotal: 0
  },
  mutations: {
    changeRepairList(state, list) {
      state.repairList = list
    },
    changeRepairTotal(state, total) {
      state.repairTotal = total
    },
  },
  actions: {
    async getRepairList({ commit }, params) {
      const res = await repairList(params)
      if (res.success) {
        commit('changeRepairList', res.data.list)
        commit('changeRepairTotal', res.data.total)
      }
    }
  }
}

export default Repair
