import requst from '@/utils/request'

// 获取合作伙伴列表
export function cooperativeList(param) {
  return requst.request({
    url: '/general/cooperative/list',
    method: 'get',
    params: param
  })
}

// 添加合作伙伴信息
export function addCooperative(data) {
  return requst.request({
    url: '/admin/cooperative/add',
    method: 'put',
    data
  })
}

//  编辑合作伙伴信息
export function editCooperative(data) {
  return requst.request({
    url: '/admin/cooperative/edit',
    method: 'post',
    data
  })
}

// 删除合作伙伴信息
export function deleteCooperative(id) {
  return requst.request({
    url: '/admin/cooperative/delete/' + id,
    method: 'delete',
  })
}