import { solutionList } from '@/api/solution/solution'
const Solution = {
  namespaced: true,
  state: {
    solutionList: '',
    solutionTotal: 0,
  },
  mutations: {
    changeSolutionList(state, list) {
      state.solutionList = list
    },
    changeSolutionTotal(state, total) {
      state.solutionTotal = total
    },
  },
  actions: {
    async getSolutionList({ commit }, params) {
      const res = await solutionList(params)
      if (res.success) {
        commit('changeSolutionList', res.data.list)
        commit('changeSolutionTotal', res.data.total)
      }
    }
  }
}

export default Solution
