import { industryList, getIndustryInfoList } from '@/api/industry/industry'
const Industry = {
  namespaced: true,
  state: {
    industryTypeList: [],
    industryList: [],
    industryTotal: 0
  },
  mutations: {
    changeIndustryList(state, list) {
      state.industryList = list
    },
    changeIndustryTotal(state, total) {
      state.industryTotal = total
    },
    changeIndustryTypeList(state, list) {
      state.industryTypeList = list
    }
  },
  actions: {
    async getIndustryList({ commit, state }, params) {
      const res = await industryList(params)
      if (res.success) {
        commit('changeIndustryList', res.data.list)
        commit('changeIndustryTotal', res.data.total)
      }
    },
    async getIndustryTypeList({ commit, state }, params) {
      const res = await getIndustryInfoList()
      if (res.success) {
        commit('changeIndustryTypeList', res.data.list)
      }
    }
  }
}

export default Industry
