import requst from '@/utils/request'

// 获取账户列表
export function getUserList(param) {
  return requst.request({
    url: '/admin/user/list',
    method: 'get',
    params: param
  })
}

// 添加账户
export function addUser(param) {
  return requst.request({
    url: '/admin/user/add',
    method: 'put',
    data: param
  })
}

// 编辑账户
export function editUser(param) {
  return requst.request({
    url: '/admin/user/edit',
    method: 'post',
    data: param
  })
}

// 删除账户
export function deleteUser(id) {
  return requst.request({
    url: '/admin/user/delete/' + id,
    method: 'delete',
  })
}

// 获取角色列表
export function getUserRoleList(param) {
  return requst.request({
    url: '/admin/user/role/list',
    method: 'get',
    params: param
  })
}

// 添加角色
export function addRole(param) {
  return requst.request({
    url: '/admin/user/role/add',
    method: 'put',
    data: param
  })
}

// 编辑角色
export function editRole(param) {
  return requst.request({
    url: '/admin/user/role/edit',
    method: 'post',
    data: param
  })
}

// 删除角色
export function deleteRole(id) {
  return requst.request({
    url: '/admin/user/role/delete/' + id,
    method: 'delete',
  })
}