import requst from '@/utils/request'

// 获取品牌列表
export function brandList(param) {
  return requst.request({
    url: '/general/brand/list',
    method: 'get',
    params: param
  })
}

// 编辑品牌
export function addBrand(data) {
  return requst.request({
    url: '/admin/brand/add',
    method: 'put',
    data
  })
}

// 编辑品牌
export function editBrand(data) {
  return requst.request({
    url: '/admin/brand/edit',
    method: 'post',
    data
  })
}

//  删除品牌
export function deletebrand(id) {
  return requst.request({
    url: '/admin/brand/delete/' + id,
    method: 'delete',
  })
}